export enum ServerActionName {
  // Account
  CreateUserAccounts = "createUserAccounts",
  DeleteUser = "deleteUser",
  GetUserById = "getUserById",
  ListUserAccount = "listUserAccount",
  SendUserVerification = "sendUserVerification",
  UpdateUserAccount = "updateUserAccount",

  // Attribute
  AddLibraryAttribute = "addLibraryAttribute",
  CreateCustomAttribute = "createCustomAttribute",
  DeleteAttribute = "deleteAttribute",
  GetAttributeInUseCount = "getAttributeInUseCount",
  GetLibraryAttributes = "getLibraryAttributes",
  GetSupplierAttributeList = "getSupplierAttributeList",
  UpdateAttribute = "updateAttribute",

  // Authentication
  Login = "login",
  ResendVerificationLink = "resendVerificationLink",
  SendResetPasswordEmail = "sendResetPasswordEmail",
  SetPassword = "setPassword",
  UpdateEmailAddress = "updateEmailAddress",
  UpdatePassword = "updatePassword",
  VerifyEmailAddress = "verifyEmailAddress",

  // Channel
  GetChannelCategories = "getChannelCategories",
  GetChannelConfigurationList = "getChannelConfigurationList",
  GetChannelList = "getChannelList",
  UpdateChannelSettings = "updateChannelSettings",
  UpdateProductChannels = "updateProductChannels",

  // Common
  GetCurrencyList = "getCurrencyList",
  GetGoogleCategoriesList = "getGoogleCategoriesList",
  GetLocaleCountries = "getLocaleCountries",
  GetTaxonomyTree = "getTaxonomyTree",
  GetTaxonomyTreeNode = "getTaxonomyTreeNode",

  // DAM
  CreateDamTask = "createDamTask",
  GetDamList = "getDamList",
  GetDamTaskList = "getDamTaskList",
  GetFileDetail = "getFileDetail",

  // Enrichment
  ApproveEnrichImages = "approveEnrichImages",
  CancelEnrichImageTasks = "cancelEnrichImageTasks",
  GetEnrichImagesReviewProducts = "getEnrichImagesReviewProducts",
  GetEnrichmentStatus = "getEnrichmentStatus",
  GetEnrichmentTaskList = "getEnrichmentTaskList",
  StartEnrichment = "startEnrichment",

  // Export
  CreateExportTemplate = "createExportTemplate",
  CreateExportTask = "createExportTask",
  DeleteExportTemplate = "deleteExportTemplate",
  DuplicateExportTemplate = "duplicateExportTemplate",
  GetAvailableFixedExportTemplateList = "getAvailableFixedExportTemplateList",
  GetAvailableCustomExportTemplateList = "getAvailableCustomExportTemplateList",
  GetCustomTemplate = "getCustomTemplate",
  GetCustomTemplateList = "getCustomTemplateList",
  GetExportTask = "getExportTask",
  GetExportTaskList = "getExportTaskList",
  UpdateCustomTemplate = "updateCustomTemplate",
  CreateExportImageTask = "createExportImageTask",

  // Feature
  GetFeatureToggles = "getFeatureToggles",
  SetFeatureToggle = "setFeatureToggle",

  // File
  DeleteFiles = "deleteFiles",
  GetFilesList = "getFilesList",
  RetrieveDownloadUrl = "retrieveDownloadUrl",
  UploadFileFromUrl = "uploadFileFromUrl",
  ReferenceFileToProduct = "referenceFileToProduct",

  // Localization
  CreateLocalizationTask = "createLocalizationTask",
  CreateBatchLocalizationTask = "createBatchLocalizationTask",
  GetLocalizationTaskList = "getLocalizationTaskList",
  GetMasterProductLocalizationStatus = "getMasterProductLocalizationStatus",

  // Product
  AddFileToGallery = "addFileToGallery",
  CreateProduct = "createProduct",
  CreateVariantProduct = "createVariantProduct",
  DeleteProduct = "deleteProduct",
  DuplicateProduct = "duplicateProduct",
  GetAvailableVariantAttributes = "getAvailableVariantAttributes",
  GetBasicProduct = "getBasicProduct",
  GetCreateProductTemplate = "getCreateProductTemplate",
  GetProduct = "getProduct",
  GetProductList = "getProductList",
  GetProductListCount = "getProductListCount",
  GetVariantProducts = "getVariantProducts",
  InitializeExportAuditLog = "initializeExportAuditLog",
  RemoveFileFromGalleryAction = "removeFileFromGalleryAction",
  UpdateAttributeBlame = "updateAttributeBlame",
  UpdateProduct = "updateProduct",
  DeleteFileAndProductReferences = "deleteFileAndProductReferences",

  // Smart Upload
  ArchiveTask = "archiveTask",
  FieldMapping = "fieldMapping",
  GenerateS3Url = "generateS3Url",
  GetActiveTasks = "getActiveTasks",
  ImportTasks = "importTasks",
  ListAttributes = "listAttributes",
}
